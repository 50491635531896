<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>Currencies</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          small
          to="/new-currency"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New Currency
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="currencies"
              :search="search"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  computed: {},
  data: () => ({
    search: "",
    currencies: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Code", value: "CurrCode" },
      { text: "Currency", value: "currency" },
    ],
  }),
  methods: {
    getCurencies() {
      const self = this;
      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getCurencies();
  },
};
</script>